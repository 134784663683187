import React, { useState, useRef } from 'react';
import { MdKeyboardArrowUp, MdKeyboardArrowDown } from 'react-icons/md';
import classnames from 'classnames';
import { TimelineLite } from 'gsap';
import { FaExternalLinkAlt } from 'react-icons/fa'

import SubPage from "../components/subpage";
import styles from './work.module.css';

import woodpecker1 from '../images/work/woodpecker-home.jpg';
import woodpecker2 from '../images/work/woodpecker-notes.jpg';
import studyassistant1 from '../images/work/studyassistant1.jpg';
import studyassistant2 from '../images/work/studyassistant2.jpg';
import studyinchina1 from '../images/work/studyinchina1.jpg';
import studyinchina2 from '../images/work/studyinchina2.jpg';
import saliya1 from '../images/work/saliyagarden1.jpg';
import saliya2 from '../images/work/saliyagarden2.jpg';

const WorkPage = () => {
    const [selectedIndex, setSelectedIndex] = useState(0);
    let text = useRef(null);
    let img1 = useRef(null);
    let img2 = useRef(null);

    const work = [
        {
            title: 'Woodpecker',
            description: `Web-based productivity application to manage tasks and notes.
                Developed with Spring Boot, React JS and Material UI. Created with usability and performance
                in mind.`,
            image1: woodpecker1,
            image2: woodpecker2,
            link: 'https://github.com/davehowson/Woodpecker'
        },
        {
            title: 'Study Assistant',
            description: `Web-based time management system to help undergraduates manage their self-study schedules
                using a study session scheduler and machine learning based reporting functionality.`,
            image1: studyassistant1,
            image2: studyassistant2,
            link: 'http://ec2-3-84-215-5.compute-1.amazonaws.com/'
        },
        {
            title: 'Study In China',
            description: `WordPress-based website for a higher education consultants agency.
                        Built to be informative & attractive with performance & ease of
                        use in mind.`,
            image1: studyinchina1,
            image2: studyinchina2,
            link: 'https://scholarshipsinchina.com'
        },
        {
            title: 'Saliya Garden',
            description: `WordPress-based website for a hotel in Anuradhapura, designed and developed
                        to give users with a sense of leisure and to attract potential guests.`,
            image1: saliya1,
            image2: saliya2,
            link: 'https://saliyagarden.com'
        }
    ];

    const handleDownClick = () => {
        let tl = new TimelineLite;
        tl.to(text, 0.2, {
            opacity:0
        })
        .to([img1, img2], 0.5, {
            opacity: 0,
            x: -10
        }, '-=0.2')
        .add(() => {
            let current = selectedIndex + 1;
            if (current >= work.length) {
                current = 0;
            }
            setSelectedIndex(current);
        })
        .add("newItemAdded")
        .to(text, 0.5, {
            opacity:1,
        }, "newItemAdded")
        .to(img1, 0.5, {
            opacity: 1,
            x: 0,
            delay: 0.2
        }, "newItemAdded")
        .to(img2, 0.5, {
            opacity: 1,
            x: 0,
            delay: 0.2
        }, "newItemAdded")

    }

    const handleUpClick = () => {
        let tl = new TimelineLite;
        tl.to(text, 0.2, {
            opacity:0
        })
        .to([img1, img2], 0.5, {
            opacity: 0,
            x: -10
        }, '-=0.2')
        .add(() => {
            let current = selectedIndex - 1;
            if (current < 0) {
                current = work.length -1;
            }
            setSelectedIndex(current);
        })
        .add("newItemAdded")
        .to(text, 0.5, {
            opacity:1,
        }, "newItemAdded")
        .to(img1, 0.5, {
            opacity: 1,
            x: 0,
            delay: 0.2
        }, "newItemAdded")
        .to(img2, 0.5, {
            opacity: 1,
            x: 0,
            delay: 0.2
        }, "newItemAdded")
    }

    const handleDotClick = (i) => {
        let tl = new TimelineLite;
        tl.to(text, 0.2, {
            opacity:0
        })
        .to([img1, img2], 0.5, {
            opacity: 0,
            x: -10
        }, '-=0.2')
        .add(() => {
            setSelectedIndex(i);
        })
        .add("newItemAdded")
        .to(text, 0.5, {
            opacity:1,
        }, "newItemAdded")
        .to(img1, 0.5, {
            opacity: 1,
            x: 0,
            delay: 0.2
        }, "newItemAdded")
        .to(img2, 0.5, {
            opacity: 1,
            x: 0,
            delay: 0.2
        }, "newItemAdded")
    }

    const dots = () => {
        let dotList = [];

        for (let i = 0; i < work.length; i++) {
            if (i === selectedIndex) {
                dotList.push(<span key={i} className={classnames(styles.dot, styles.selected)} onClick={() => handleDotClick(i)}></span>)
            } else {
                dotList.push(<span key={i} className={styles.dot} onClick={() => handleDotClick(i)}></span>)
            }

        }

        return dotList;
    }


    return (
        <SubPage title='work'>
            <div className={styles.work}>
                <div className={styles.nav}>
                    <MdKeyboardArrowUp className={styles.arrow} onClick={handleUpClick}/>
                    {dots()}
                    <MdKeyboardArrowDown className={styles.arrow} onClick={handleDownClick}/>
                </div>
                <div className={styles.images}>
                    <img src={work[selectedIndex].image1} className={styles.imageOne} ref={el => {img1 = el}}/>
                    <img src={work[selectedIndex].image2} className={styles.imageTwo} ref={el => {img2 = el}}/>
                </div>
                <div className={styles.text} ref={el => {text = el}}>
                    <h4 className={styles.header}>{work[selectedIndex].title}</h4>
                    <p className={styles.details}>{work[selectedIndex].description}</p>
                    <a href={work[selectedIndex].link} target="_blank" rel="noopener noreferrer" className={styles.link}><FaExternalLinkAlt/>&nbsp;&nbsp;Visit</a>
                </div>
            </div>
        </SubPage>
    )
}

export default WorkPage;
